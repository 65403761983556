import cn from 'classnames'
import { FC } from 'react'
import { parseError } from 'common/utils/parseError'

interface Messages {
  /** The error message to be displayed, replaces \n */
  className?: string
  icon?: string
  'data-test'?: string
  children?: React.ReactNode | Object
}

//Generic error message
const Message: FC<Messages> = ({
  children,
  className,
  'data-test': dataTest,
  icon,
}) => {
  if (!children) {
    return null
  }

  return (
    <div data-test={dataTest} className={`alert mt-2 ${className || ''}`}>
      <div className='flex-row flex-nowrap'>
        {icon && <span className={cn({ icon: true }, 'me-3', icon)} />}
        <span data-test='message'>
          {typeof children === 'string'
            ? children.replace(/\n/g, '')
            : 'Error processing request'}
        </span>
      </div>
    </div>
  )
}

//Default message added alert-danger
export const ErrorMessage: FC<Messages> = ({ className, ...props }) => (
  <Message
    {...props}
    icon={props.icon || 'fas fa-exclamation-circle'}
    className={cn(className, 'alert-danger text-danger')}
  >
    {parseError(props.children)}
  </Message>
)

// Default message added alert-success
export const SuccessMessage: FC<Messages> = ({ className, ...props }) => (
  <Message
    {...props}
    icon={props.icon || 'fas fa-check-circle'}
    className={cn(className, 'alert-success text-success')}
  />
)

Message.displayName = 'ErrorMessage'
