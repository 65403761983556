'use client'
import CircularProgress from '@mui/material/CircularProgress'

const LoadingOverlay = () => {
  return (
    <div
      className='vh-100 vw-100 bg-dark justify-content-center align-items-center d-flex position-absolute'
      style={{
        zIndex: 9,
      }}
    >
      <CircularProgress color='inherit' />
    </div>
  )
}

export default LoadingOverlay
