'use client'
import React from 'react'
import { useRouter } from 'next/navigation'
import Skeleton from '@mui/material/Skeleton'

import { Service } from 'common/types/responses'
import { ErrorMessage } from 'components/Messages'
import ServiceItem from 'components/booking/ServiceItem'
import { useGetServicesQuery } from 'common/services/useService'
import { useDraftBooking } from 'common/services/useDraftBooking'

const ServicesSection = ({}) => {
  const router = useRouter()
  const { draftBooking } = useDraftBooking()
  const { data: services, error, isLoading } = useGetServicesQuery({})

  const handleBookNow = (service: Service) => {
    router.push(`/booking/add-detail?service=${service.code}`)
  }

  return (
    <div className='flex-row align-items-stretch'>
      {services ? (
        services?.map((service) => {
          return (
            <div className='col-4 col-md-3' key={service.code}>
              <ServiceItem
                isSelected={draftBooking?.service === service.code}
                service={service}
                onClick={() => {
                  handleBookNow(service)
                }}
              />
            </div>
          )
        })
      ) : isLoading ? (
        <Skeleton
          className='rounded-3'
          variant='rectangular'
          height={300}
          width={'100%'}
        />
      ) : (
        <ErrorMessage>{error?.message}</ErrorMessage>
      )}
    </div>
  )
}
export default ServicesSection
