import { useEffect, useState } from 'react'
import { useUser } from 'common/services/useUser'
import { getApi } from 'common/api'
import { Res } from 'common/types/responses'

export default function useNotLoggedInRedirect(): {
  user?: Res['user']
  isReady: boolean
} {
  const { data: user } = useUser({})
  const [isReady, setIsReady] = useState<boolean>(!!user)

  useEffect(() => {
    if (!user?.data?.id && typeof window !== 'undefined') {
      getApi().logoutRedirect?.()
    } else {
      setIsReady(true)
    }
  }, [user?.data?.id, isReady])
  return {
    isReady,
    user,
  }
}
