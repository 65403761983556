import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { toParam } from 'common/utils/urls/param'

export const bookingService = service
  .enhanceEndpoints({ addTagTypes: ['Booking'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getBooking: builder.query<Res['booking'], Req['getBooking']>({
        providesTags: (res) => [{ id: res?.data.id, type: 'Booking' }],
        query: (query: Req['getBooking']) => ({
          url: `customer/bookings/${query.id}`,
        }),
      }),

      getBookings: builder.query<Res['bookings'], Req['getBookings']>({
        providesTags: [{ id: 'LIST', type: 'Booking' }],
        query: (q) => ({
          url: `customer/bookings?${toParam(q)}`,
        }),
      }),
      // END OF ENDPOINTS
    }),
  })

export async function getBookings(
  store: any,
  data: Req['getBookings'],
  options?: Parameters<typeof bookingService.endpoints.getBookings.initiate>[1],
) {
  store.dispatch(bookingService.endpoints.getBookings.initiate(data, options))
  return Promise.all(
    store.dispatch(bookingService.util.getRunningQueriesThunk()),
  )
}
export async function getBooking(
  store: any,
  data: Req['getBooking'],
  options?: Parameters<typeof bookingService.endpoints.getBooking.initiate>[1],
) {
  store.dispatch(bookingService.endpoints.getBooking.initiate(data, options))
  return Promise.all(
    store.dispatch(bookingService.util.getRunningQueriesThunk()),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useGetBookingQuery,
  useGetBookingsQuery,
  // END OF EXPORTS
} = bookingService

/* Usage examples:
const { data, isLoading } = useGetBookingsQuery({ id: 2 }, {}) //get hook
const [createBookings, { isLoading, data, isSuccess }] = useCreateBookingsMutation() //create hook
bookingService.endpoints.getBookings.select({id: 2})(store.getState()) //access data from any function
*/
