export function parseError(e?: any) {
  if (typeof e?.data === 'string') {
    if (e.data.startsWith('{')) {
      return JSON.parse(e.data)?.message
    }
    return e.data
  }
  if (typeof e?.data?.message === 'string') {
    return e.data?.message
  }
  if (e?.status) {
    return `Error: ${e.status}`
  }

  const message = e?.message?.message || e?.message
  if (typeof message === 'string') {
    return message
  }
  return 'An unknown error occurred'
}
