'use-client'
import dayjs from 'dayjs'
import { FC } from 'react'
import { useRouter } from 'next/navigation'

import Address from 'components/Address'
import Button from 'components/base/forms/Button'
import { BookingSummary } from 'common/types/responses'
import { ServiceIcons } from 'components/svgs/serviceIcons'
import Link from 'next/link'

interface Props {
  data: BookingSummary
  status: string
}

export const JobCard: FC<Props> = ({ data, status }) => {
  const router = useRouter()
  const Icon = ServiceIcons[data.service.code]

  return (
    <div className='ps-0 pe-0 card job-card'>
      <div className='d-flex flex-row justify-content-between px-5 pt-4'>
        <div className='d-flex flex-column'>
          <h6>{status}</h6>
          <span>
            <Icon className='me-2' color={'#E872C8'} />
            {data.service.name}
          </span>
        </div>
        <div className='d-flex flex-column text-end'>
          <span className='text-sm fw-bold text-primary'>
            {data.appointment.length
              ? dayjs(data.appointment[0].time).format('ddd DD MMM - A')
              : `${dayjs(data.preferred_date).format('ddd DD MMM')} - ${
                  data.required_time
                }`}
          </span>
          <span className='fw-bold text-secondary'>
            {data.service.fixed_rate
              ? `£${data.service.fixed_rate}`
              : `From £${data.service.hour_rate}/ph`}
          </span>
          <span className='text-xs text-grey'>Plus VAT</span>
        </div>
      </div>
      <div style={{ height: 1 }} className='my-2 bg-midnight-l w-fit mx-5' />
      <div className='px-5 pb-5 d-flex flex-column h-100 justify-content-between'>
        <Address address={data.address} />
        <Link href={`/bookings/${data.id}`}>
          <Button className='w-100'>View Booking</Button>
        </Link>
      </div>
    </div>
  )
}
