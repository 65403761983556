import { FC } from 'react'

import LocationPinIcon from './svgs/LocationPin'
import { BookingAddress } from 'common/types/responses'

interface Props {
  address: BookingAddress
}

const Address: FC<Props> = ({ address }) => {
  return (
    <div className='flex-row align-items-start flex-nowrap'>
      <LocationPinIcon className='flex-shrink-0' />
      <div className='d-flex flex-column'>
        <h6 className='fw-normal mb-0'>Address</h6>
        <span className='text-secondary text-sm'>
          {address.address_line_1}, {address.address_line_2} {address.postcode}
        </span>
      </div>
    </div>
  )
}

export default Address
