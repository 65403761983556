'use client'
import Link from 'next/link'
import { useEffect } from 'react'

import { redirect } from 'next/navigation'

import Button from 'components/base/forms/Button'
import { useUser } from 'common/services/useUser'
import HomeTemplate from './components/HomeTemplate'
import LoadingOverlay from './components/LoadingOverlay'
import InputGroup from 'components/base/forms/InputGroup'
import ServicesSection from './components/ServicesSection'
import ActiveJobsSection from 'components/ActiveJobsSection'
import { useGetBookingsQuery } from 'common/services/useBooking'
import { useDraftBooking } from 'common/services/useDraftBooking'
import useNotLoggedInRedirect from 'common/hooks/useNotLoggedInRedirect'
import { BadgeCheckIcon, FingerCrossIcon, SirenIcon } from 'components/svgs'
import { useGetPropertyAddressQuery } from 'common/services/usePropertyAddress'

const Home = ({}) => {
  useNotLoggedInRedirect()
  const { data } = useUser({})
  const user = data?.data
  const { data: propertyAddress, isLoading: loadingProperties } =
    useGetPropertyAddressQuery({})
  const { draftBooking, setDraftBooking } = useDraftBooking()
  const { data: upcomingBookings, isLoading: loadingUpcomingBookings } =
    useGetBookingsQuery({
      per_page: 20,
    })

  if (user && !loadingProperties && !propertyAddress?.data?.length) {
    redirect('/complete-profile')
  }

  useEffect(() => {
    if (draftBooking) {
      setDraftBooking({})
    }
  }, [draftBooking?.service])

  if (user) {
    return (
      <div>
        <HomeTemplate>
          <h3>Hey {user.first_name}!</h3>
          <div className='d-flex flex-column flex-md-row gap-4 '>
            <div className='d-flex gap-2 align-items-center'>
              <BadgeCheckIcon color='#fff' />
              <span className='text-sm fw-medium'>
                Verified and accredited tradespeople
              </span>
            </div>
            <div className='d-flex gap-2 align-items-center'>
              <SirenIcon color='#fff' />
              <span className='text-sm fw-medium'>
                30 mins emergency response
              </span>
            </div>
            <div className='d-flex gap-2 col-md-3 align-items-center'>
              <FingerCrossIcon color='#fff' />
              <span className='text-sm fw-medium'>12 Months guarantee</span>
            </div>
          </div>
          {/* <InputGroup
            placeholder='e.g. I have a leak under my sink...'
            className='mt-4'
            onChange={() => {}}
          />
          <Link href={'/booking/select-service'}>
            <Button className='mt-5'>Find a Fixer</Button>
          </Link> */}
          <ActiveJobsSection upcomingBookings={upcomingBookings?.data} />
          <h3 className='my-8'>What service do you need?</h3>
          <ServicesSection />
        </HomeTemplate>
      </div>
    )
  }
  return <LoadingOverlay />
}

export default Home
