import dayjs from 'dayjs'
import { FC, useMemo } from 'react'

import { JobCard } from 'app/components/JobCard'
import { BookingSummary } from 'common/types/responses'

interface Props {
  upcomingBookings?: BookingSummary[]
}

const currentTime = new Date()
const ActiveJobsSection: FC<Props> = ({ upcomingBookings }) => {
  const getNearestBooking = () => {
    const validBooking = upcomingBookings?.filter((booking) =>
      booking.state === 'FIXER_BOOKED' &&
      booking.appointment[0] &&
      dayjs(booking.appointment[0].time_to).isAfter(dayjs(currentTime))
        ? booking
        : null,
    )
    const nearestBooking = validBooking?.sort((a, b) =>
      dayjs(a.appointment[0].time_to).diff(dayjs(b.appointment[0].time_to)),
    )
    return nearestBooking
  }

  const nearestBooking = useMemo(() => {
    return getNearestBooking()
  }, [upcomingBookings])

  if (nearestBooking?.length) {
    return (
      <>
        <h3 className='my-8'>Active Jobs</h3>
        <div className='row gap-5 overflow-x-auto flex-nowrap'>
          <JobCard data={nearestBooking[0]} status='Active' />
          {nearestBooking[1] && (
            <JobCard data={nearestBooking[1]} status='Upcoming' />
          )}
        </div>
      </>
    )
  }
  return null
}

export default ActiveJobsSection
