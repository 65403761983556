import classNames from 'classnames'
import { Service } from 'common/types/responses'
import { ServiceIcons } from 'components/svgs/serviceIcons'
import React from 'react'

interface ServiceItemProps {
  service: Service
  onClick?: () => void
  isSelected?: boolean
}

const ServiceItem: React.FC<ServiceItemProps> = ({
  isSelected,
  onClick,
  service,
}) => {
  const Icon = ServiceIcons[service.code]

  return (
    <div
      id={`select-service-${service.code}`}
      onClick={onClick}
      style={{
        maxWidth: 158,
      }}
      className={classNames(
        'py-4 pb-6 d-flex flex-column align-items-center my-4 text-center cursor-pointer',
        {
          'bg-midnight-md rounded-3': isSelected,
        },
      )}
    >
      <Icon
        id={`select-service-${service.code}`}
        color={isSelected ? '#50D7C8  ' : '#FFFFFF'}
        height={42}
        width={42}
      />
      <span id={`select-service-${service.code}`} className='text-lg pt-2'>
        {service.name}
      </span>
    </div>
  )
}

export default ServiceItem
